<template>
  <div>
    <v-file-input
      :value="file"
      :label="required ? label + '*' : label" 
      outlined
      :disabled="disabled"
      @change="updateFile"
      background-color="white"
      :accept="accept"
      :error="errors && errors.length > 0"
      :class="errors && errors.length ? 'hasError' : ''"
      hide-details
      :prepend-icon="null"
    >
      <template v-slot:prepend-inner><v-icon>mdi-paperclip</v-icon></template>
      <template v-slot:append><v-icon class="error-icon" v-if="errors && errors.length">mdi-alert</v-icon></template>
    </v-file-input>

    <v-alert type="info" dense dismissible v-model="showMaxMbExceeded" class="mt-2">
        File would exceed upload size limits.
    </v-alert>

    <div style="text-align: center; margin-top: 10px;" v-if="newValue">
        <ImageThumbnail 
            :path="filePath" 
            :display="newValue.DisplayName" 
            :disabled="disabled" 
            @remove="updateFile(null)"
        />
    </div>
    

    <ErrorMessages :errors="errors"></ErrorMessages>
  </div>
</template>

<script>
import ImageThumbnail from '@/components/shared/ImageThumbnail.vue'
import { resizeImage, sanitizeFileName } from '@/scripts/helper'
import { errorHandler } from '@/plugins/axios'
import { getTokenAsync } from '@/plugins/auth'

export default {
    name: 'FileUploadSingle',
    components: {
        ImageThumbnail
    },
    props: {
        value: Object,
        rules: String,
        label: String,
        required: Boolean,
        errors: Array,
        accept: {
            type: String,
            default: "image/*, .pdf, .doc, .docx"
        },
        disabled: Boolean,
        maxMb: {
            type: Number,
            default: 50
        } 
    },
    emits: [
        'change'
    ],
    data() {
        return{
          file: null,
          newValue: null,
          showMaxMbExceeded: false,
        }   
    },
    methods: {
      async updateFile(file) {
        this.file = file;
        this.showMaxMbExceeded = false;

        if (file) {
          file = await resizeImage(file);
        }

        if (file && file.size / 1000000 > this.maxMb) {
          this.showMaxMbExceeded = true;
          return;
        }

        if (file) {
          const requestURL = '/api/TempFile?displayName=' + encodeURI(sanitizeFileName(file.name));
          const options = {
            headers: {
              "Authorization": "Bearer " + await getTokenAsync()
            },
            handleError: false
          }

          try {
            const response = await this.$http.post(requestURL, file, options);
            this.newValue = response.data;
          } catch (err) {
            if (err.response && err.response.status == 403) {
              this.$store.dispatch('toastError', {Message: 'Corrupted File. Please resave the file and try again.'});
            } else {
              errorHandler(err);
            }

            this.file = null;
            this.newValue = null;
          }

        } else {
          this.newValue = null;          
        }

        this.$emit('change', this.newValue);
        
      }

    },
    mounted() {
      if (this.value) {
        this.newValue = this.value;
      }
    },
    computed: {
      filePath() {
        const baseURL = this.$http.defaults.baseURL;
        return baseURL + '/api/TempFile/' + this.newValue.FileName;
      }
    },
    watch: {
      value: {
          immediate: true,
          handler(newVal) {
            if (newVal) {
              this.newValue = newVal;
            }
            
          }
      },
    },
    
}

</script>

<style scoped lang="scss">
    @import '@/style.scss';

    .file-name {
        color: $amwins-blue;
        text-decoration: none;
        cursor: pointer;
    }

    ::v-deep .v-input--is-label-active .v-input__prepend-inner,
    ::v-deep .v-input--is-dirty .v-input__prepend-inner, 
    ::v-deep .v-input--is-focused .v-input__prepend-inner,
    ::v-deep .v-input--is-label-active .v-input__prepend-inner,
    ::v-deep .v-input--is-dirty .v-input__prepend-inner, 
    ::v-deep .v-input--is-focused .v-input__prepend-inner {
        margin-top: 22px;
    }

</style>